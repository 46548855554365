<template>
  <div v-if="analyticsData">
    <b-row>
      <b-col>
        <AnalyticsDateSelector
          title="LOCATION DASHBOARD"
          :is-loading="isLoading"
          @handleExtract="handleExtract"
          @handleExport="handleExport"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col
        v-for="(item, index) in states"
        :key="index"
        cols="12"
        lg="4"
      >
        <b-card>
          <b-list-group>
            <b-list-group-item class="border-0 d-flex justify-content-between font-weight-bolder">
              <h3 class="m-0">
                {{ item.label }}
              </h3>
              <h3 class="m-0">
                {{ item.value }}
              </h3>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col class="text-center">
        <b-button
          variant="primary"
          @click="$router.push({ name: 'dashboard' })"
        >
          Go back
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { AnalyticsDateSelector } from '@/components'
import { analytics } from '@/mixins/analytics'

export default {
  name: 'LocationAnalytics',
  components: {
    AnalyticsDateSelector,
  },
  mixins: [analytics],
  data() {
    return {
      states: [],
      type: 'location',
      hasGrahp: false,
    }
  },
  watch: {
    analyticsData(newValue) {
      this.states = newValue
    },
  },
}
</script>
